import { jsonMember, jsonObject } from 'typedjson';
import { OfferStatus } from '@features/offers/components/offer-status/enums/offer-status';
import { Uuid } from '@core/uuid/uuid';
import { OfferCollectionItemOperationsResponse } from '@api/offers/responses/offer-collection-item-operations.response';
import { OfferVisibilityFor } from '@features/offers/components/offer-visibility-for/enums/offer-visibility-for';
import { FilePreviewResponse } from '@api/files/responses/file-preview.response';

@jsonObject
export class OfferCollectionItemResponse {
  @jsonMember readonly id!: Uuid;
  @jsonMember readonly name!: string;
  @jsonMember readonly lead!: string;
  @jsonMember readonly identifier!: string;
  @jsonMember readonly status!: OfferStatus;
  @jsonMember readonly isDisposable!: boolean;
  @jsonMember readonly visibility!: OfferVisibilityFor;
  @jsonMember readonly expiresAt!: Date;
  @jsonMember readonly createdAt!: Date;
  @jsonMember({
    name: 'contextOperations',
  })
  readonly operations!: OfferCollectionItemOperationsResponse;
  @jsonMember readonly file?: FilePreviewResponse;
}
