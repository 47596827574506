import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@core/uuid/uuid';
import { OfferStatus } from '@features/offers/components/offer-status/enums/offer-status';
import { OfferVisibilityFor } from '@features/offers/components/offer-visibility-for/enums/offer-visibility-for';
import { OfferDetailsOperationsResponse } from '@api/offers/responses/offer-details-operations.response';
import { FilePreviewResponse } from '@api/files/responses/file-preview.response';

@jsonObject
export class OfferDetailsResponse {
  @jsonMember readonly id!: Uuid;
  @jsonMember readonly name!: string;
  @jsonMember readonly lead!: string;
  @jsonMember readonly description!: string;
  @jsonMember readonly identifier!: string;
  @jsonMember readonly status!: OfferStatus;
  @jsonMember readonly isDisposable!: boolean;
  @jsonMember({ name: 'visibility' })
  readonly visibilityFor!: OfferVisibilityFor;
  @jsonMember readonly beginsAt!: Date;
  @jsonMember readonly expiresAt!: Date;
  @jsonMember readonly createdAt!: Date;
  @jsonMember({ name: 'contextOperations' })
  readonly operations!: OfferDetailsOperationsResponse;
  @jsonMember readonly file?: FilePreviewResponse;
  @jsonMember({ name: 'termsId' }) readonly regulationId?: Uuid;
}
