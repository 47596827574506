import { OfferContextOperations } from '@features/offers/models/offer-context-operations';
import { Uuid } from '@core/uuid/uuid';
import { OfferStatus } from '@features/offers/components/offer-status/enums/offer-status';
import { OfferVisibilityFor } from '@features/offers/components/offer-visibility-for/enums/offer-visibility-for';
import { ImageFile } from '@features/files/models/image-file';
import { Nullable } from '@core/utils/types/nullable/nullable';

export class Offer {
  constructor(
    readonly id: Uuid,
    readonly name: string,
    readonly lead: string,
    readonly description: string,
    readonly identifier: string,
    readonly status: OfferStatus,
    readonly isDisposable: boolean,
    readonly visibilityFor: OfferVisibilityFor,
    readonly beginsAt: Date,
    readonly expiresAt: Date,
    readonly createdAt: Date,
    readonly image: Nullable<ImageFile>,
    readonly operations: OfferContextOperations,
    readonly regulationId: Nullable<Uuid>,
  ) {}
}
