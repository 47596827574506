import { Injectable } from '@angular/core';
import { RouterPath } from '../enums/router-path';
import { RouterLinkParts } from '../types/router-link-parts';
import { RouterPathFragment } from '../enums/router-path-fragment';
import { RouterLinkParamsProcessorService } from '../services/router-link-params-processor.service';
import { RouterPathParams } from '../types/router-path-params';
import { RouterLink } from '../types/router-link';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { RouterPathParam } from '@core/routing/enums/router-path-param';

@Injectable({
  providedIn: 'root',
})
export class RouterLinkProvider {
  private routerLinks: Record<RouterPath, RouterLinkParts> = {
    [RouterPath.ROOT]: [
      RouterPathFragment.ROOT, //
    ],
    [RouterPath.SIGN_IN]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.AUTH,
      RouterPathFragment.SIGN_IN,
    ],
    [RouterPath.SIGN_OUT]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.AUTH,
      RouterPathFragment.SIGN_OUT,
    ],
    [RouterPath.ACTIVATION]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.AUTH,
      RouterPathFragment.ACTIVATION,
      RouterPathParam.ACTIVATION_TOKEN,
    ],
    [RouterPath.RECOVER_PASSWORD]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.AUTH,
      RouterPathFragment.RECOVER_PASSWORD,
    ],
    [RouterPath.RESET_PASSWORD]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.AUTH,
      RouterPathFragment.RESET_PASSWORD,
      RouterPathParam.ACTIVATION_TOKEN,
    ],
    [RouterPath.CUSTOMERS]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.CUSTOMERS,
    ],
    [RouterPath.CUSTOMER_PREVIEW_WALLET]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.CUSTOMERS,
      RouterPathParam.CUSTOMER_ID,
      RouterPathFragment.WALLET,
    ],
    [RouterPath.OFFERS]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.OFFERS,
    ],
    [RouterPath.CREATE_OFFER]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.OFFERS,
      RouterPathFragment.CREATE,
    ],
    [RouterPath.UPDATE_OFFER]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.OFFERS,
      RouterPathParam.OFFER_ID,
      RouterPathFragment.UPDATE,
    ],
    [RouterPath.SHARE_PREVIEW_OFFER]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.OFFERS,
      RouterPathParam.OFFER_ID,
      RouterPathFragment.SHARE_PREVIEW,
    ],
    [RouterPath.ADMINS]: [RouterPathFragment.ROOT, RouterPathFragment.ADMINS],
    [RouterPath.EMPLOYEES]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.EMPLOYEES,
    ],
    [RouterPath.STATISTICS]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.STATISTICS,
    ],
    [RouterPath.SUBSCRIPTION_STATISTICS]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.STATISTICS,
      RouterPathFragment.SUBSCRIPTIONS,
    ],
    [RouterPath.REGULATIONS]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.REGULATIONS,
    ],
    [RouterPath.PREVIEW_REGULATION]: [
      RouterPathFragment.ROOT, //
      RouterPathFragment.REGULATIONS,
      RouterPathParam.REGULATION_ID,
      RouterPathFragment.PREVIEW,
    ],
    [RouterPath.CREATE_REGULATION]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.REGULATIONS,
      RouterPathFragment.CREATE,
    ],
    [RouterPath.UPDATE_REGULATION]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.REGULATIONS,
      RouterPathParam.REGULATION_ID,
      RouterPathFragment.UPDATE,
    ],
    [RouterPath.NOT_FOUND]: [
      RouterPathFragment.ROOT,
      RouterPathFragment.NOT_FOUND,
    ],
  };

  constructor(
    private readonly paramsProcessor: RouterLinkParamsProcessorService,
  ) {}

  routerLink(
    path: RouterPath,
    params?: Nullable<RouterPathParams>,
  ): RouterLink {
    return this.paramsProcessor.process(this.routerLinks[path], params);
  }
}
