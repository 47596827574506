import { Routes } from '@angular/router';
import { RouterPathFragment } from '@core/routing/enums/router-path-fragment';
import { RouterPathParam } from '@core/routing/enums/router-path-param';
import { OnlyNotLoggedGuard } from '@core/auth/guards/only-not-logged.guard';
import { OnlyLoggedGuard } from '@core/auth/guards/only-logged.guard';
import { RouterPathData } from '@core/routing/enums/router-path-data';
import { SharePreviewOfferContentResolver } from '@features/offers/resolvers/share-preview-offer-content.resolver';
import { OfferResolver } from '@features/offers/resolvers/offer.resolver';
import { RegulationResolver } from '@features/regulations/resolvers/regulation.resolver';
import { PreviewRegulationContentResolver } from '@features/regulations/resolvers/preview-regulation-content.resolver';

/* prettier-ignore */
export const routes: Routes = [
  {
    path: RouterPathFragment.AUTH,
    loadComponent: () => import('@scaffolds/components/auth-scaffold/auth-scaffold.component').then(({ AuthScaffoldComponent }) => AuthScaffoldComponent),
    children: [
      {
        path: `${RouterPathFragment.SIGN_IN}/:${RouterPathParam.ACTIVATION_TOKEN}`,
        canActivate: [OnlyNotLoggedGuard],
        loadComponent: () => import('@features/auth/pages/reset-password-page/reset-password-page.component').then(({ ResetPasswordPageComponent }) => ResetPasswordPageComponent),
      },
      {
        path: RouterPathFragment.SIGN_IN,
        canActivate: [OnlyNotLoggedGuard],
        loadComponent: () => import('@features/auth/pages/sign-in-page/sign-in-page.component').then(({ SignInPageComponent }) => SignInPageComponent),
      },
      {
        path: RouterPathFragment.SIGN_OUT,
        loadComponent: () => import('@features/auth/pages/sign-out-page/sign-out-page.component').then(({ SignOutPageComponent }) => SignOutPageComponent),
      },
      {
        path: `${RouterPathFragment.ACTIVATION}/:${RouterPathParam.ACTIVATION_TOKEN}`,
        canActivate: [OnlyNotLoggedGuard],
        loadComponent: () => import('@features/auth/pages/activation-page/activation-page.component').then(({ ActivationPageComponent }) => ActivationPageComponent),
      },
      {
        path: `${RouterPathFragment.RECOVER_PASSWORD}`,
        canActivate: [OnlyNotLoggedGuard],
        loadComponent: () => import('@features/auth/pages/recover-password-page/recover-password-page.component').then(({ RecoverPasswordPageComponent }) => RecoverPasswordPageComponent),
      },
      {
        path: RouterPathFragment.EMPTY,
        redirectTo: RouterPathFragment.SIGN_IN,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: RouterPathFragment.EMPTY,
    canActivate: [OnlyLoggedGuard],
    children: [
      {
        path: `${RouterPathFragment.OFFERS}/:${RouterPathParam.OFFER_ID}/${RouterPathFragment.SHARE_PREVIEW}`,
        resolve: {
          [RouterPathData.CONTENT]: SharePreviewOfferContentResolver,
        },
        loadComponent: () => import('@features/offers/pages/share-preview-offer-content-page/share-preview-offer-content-page.component').then(({ SharePreviewOfferContentPageComponent }) => SharePreviewOfferContentPageComponent),
      },
      {
        path: `${RouterPathFragment.REGULATIONS}/:${RouterPathParam.REGULATION_ID}/${RouterPathFragment.PREVIEW}`,
        resolve: {
          [RouterPathData.CONTENT]: PreviewRegulationContentResolver,
        },
        loadComponent: () => import('@features/regulations/pages/preview-regulation-content-page/preview-regulation-content-page.component').then(({ PreviewRegulationContentPageComponent }) => PreviewRegulationContentPageComponent),
      },
      {
        path: RouterPathFragment.EMPTY,
        loadComponent: () => import('@scaffolds/components/dashboard-scaffold/dashboard-scaffold.component').then(({ DashboardScaffoldComponent }) => DashboardScaffoldComponent),
        children: [
          {
            path: RouterPathFragment.CUSTOMERS,
            children: [
              {
                path: `:${RouterPathParam.CUSTOMER_ID}/${RouterPathFragment.WALLET}`,
                pathMatch: 'full',
                loadComponent: () => import('@features/customers/pages/customer-wallet-operation-list-page/customer-wallet-operation-list-page.component').then(({ CustomerWalletOperationListPageComponent }) => CustomerWalletOperationListPageComponent),
              },
              {
                path: RouterPathFragment.EMPTY,
                loadComponent: () => import('@features/customers/pages/customer-list-page/customer-list-page.component').then(({ CustomerListPageComponent }) => CustomerListPageComponent),
              },
              {
                path: RouterPathFragment.WILDCARD,
                redirectTo: RouterPathFragment.EMPTY,
              },
            ],
          },
          {
            path: RouterPathFragment.REGULATIONS,
            children: [
              {
                path: RouterPathFragment.EMPTY,
                pathMatch: 'full',
                loadComponent: () => import('@features/regulations/pages/regulation-list-page/regulation-list-page.component').then(({ RegulationListPageComponent }) => RegulationListPageComponent),
              },
              {
                path: RouterPathFragment.CREATE,
                loadComponent: () => import('@features/regulations/pages/create-regulation-page/create-regulation-page.component').then(({ CreateRegulationPageComponent }) => CreateRegulationPageComponent),
              },
              {
                path: `:${RouterPathParam.REGULATION_ID}/${RouterPathFragment.UPDATE}`,
                resolve: {
                  [RouterPathData.DETAILS]: RegulationResolver,
                },
                loadComponent: () => import('@features/regulations/pages/update-regulation-page/update-regulation-page.component').then(({ UpdateRegulationPageComponent }) => UpdateRegulationPageComponent),
              },
              {
                path: RouterPathFragment.WILDCARD,
                redirectTo: RouterPathFragment.EMPTY,
              },
            ],
          },
          {
            path: RouterPathFragment.OFFERS,
            children: [
              {
                path: RouterPathFragment.EMPTY,
                pathMatch: 'full',
                loadComponent: () => import('@features/offers/pages/offer-list-page/offer-list-page.component').then(({ OfferListPageComponent }) => OfferListPageComponent),
              },
              {
                path: RouterPathFragment.CREATE,
                loadComponent: () => import('@features/offers/pages/create-offer-page/create-offer-page.component').then(({ CreateOfferPageComponent }) => CreateOfferPageComponent),
              },
              {
                path: `:${RouterPathParam.OFFER_ID}/${RouterPathFragment.UPDATE}`,
                resolve: {
                  [RouterPathData.DETAILS]: OfferResolver,
                },
                loadComponent: () => import('@features/offers/pages/update-offer-page/update-offer-page.component').then(({ UpdateOfferPageComponent }) => UpdateOfferPageComponent),
              },
              {
                path: RouterPathFragment.WILDCARD,
                redirectTo: RouterPathFragment.EMPTY,
              },
            ],
          },
          {
            path: RouterPathFragment.ADMINS,
            children: [
              {
                path: RouterPathFragment.EMPTY,
                pathMatch: 'full',
                loadComponent: () => import('@features/admins/pages/admin-list-page/admin-list-page.component').then(({ AdminListPageComponent }) => AdminListPageComponent),
              },
              {
                path: RouterPathFragment.WILDCARD,
                redirectTo: RouterPathFragment.EMPTY,
              },
            ],
          },
          {
            path: RouterPathFragment.EMPLOYEES,
            children: [
              {
                path: RouterPathFragment.EMPTY,
                pathMatch: 'full',
                loadComponent: () => import('@features/employees/pages/employee-list-page/employee-list-page.component').then(({ EmployeeListPageComponent }) => EmployeeListPageComponent),
              },
              {
                path: RouterPathFragment.WILDCARD,
                redirectTo: RouterPathFragment.EMPTY,
              },
            ],
          },
          {
            path: RouterPathFragment.STATISTICS,
            children: [
              {
                path: RouterPathFragment.EMPTY,
                loadComponent: () => import('@features/statistics/pages/statistics-page/statistics-page.component').then(({ StatisticsPageComponent }) => StatisticsPageComponent),
                children: [
                  {
                    path: RouterPathFragment.SUBSCRIPTIONS,
                    pathMatch: 'full',
                    loadComponent: () => import('@features/statistics/pages/subscription-statistics-page/subscription-statistics-page.component').then(({ SubscriptionStatisticsPageComponent }) => SubscriptionStatisticsPageComponent),
                  },
                  {
                    path: RouterPathFragment.WILDCARD,
                    redirectTo: RouterPathFragment.SUBSCRIPTIONS
                  },
                ]
              },
              {
                path: RouterPathFragment.WILDCARD,
                redirectTo: RouterPathFragment.EMPTY,
              },
            ],
          },
          {
            path: RouterPathFragment.EMPTY,
            redirectTo: RouterPathFragment.CUSTOMERS,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: RouterPathFragment.EMPTY,
    children: [
      {
        path: RouterPathFragment.PROMOTION,
        loadComponent: () => import('@scaffolds/components/public-scaffold/public-scaffold.component').then(({ PublicScaffoldComponent }) => PublicScaffoldComponent),
        children: [
          {
            path: RouterPathFragment.EMPTY,
            loadComponent: () => import('@features/promotion/pages/promotion-enroll-page/promotion-enroll-page.component').then(({ PromotionEnrollPageComponent }) => PromotionEnrollPageComponent),
          },
          {
            path: RouterPathFragment.WILDCARD,
            redirectTo: RouterPathFragment.EMPTY,
          },
        ]
      },
      {
        path: RouterPathFragment.WILDCARD,
        redirectTo: RouterPathFragment.EMPTY,
      },
    ]
  },
  {
    path: RouterPathFragment.WILDCARD,
    redirectTo: RouterPathFragment.AUTH,
  },
];
