import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@core/uuid/uuid';
import { OfferVisibilityFor } from '@features/offers/components/offer-visibility-for/enums/offer-visibility-for';
import { OfferStatus } from '@features/offers/components/offer-status/enums/offer-status';
import { dateSerializer } from '@core/json/serializers/date-serializer';
import { YYYY_MM_DD_HH_MM_SS } from '@core/locale/consts/date-format';

@jsonObject
export class CreateOfferRequest {
  @jsonMember readonly name!: string;
  @jsonMember readonly lead!: string;
  @jsonMember readonly description!: string;
  @jsonMember readonly identifier!: string;
  @jsonMember readonly status!: OfferStatus;
  @jsonMember readonly isDisposable!: boolean;
  @jsonMember({ name: 'visibility' })
  readonly visibilityFor!: OfferVisibilityFor;
  @jsonMember({ serializer: dateSerializer(YYYY_MM_DD_HH_MM_SS) })
  readonly beginsAt!: Date;
  @jsonMember({ serializer: dateSerializer() }) readonly expiresAt!: Date;
  @jsonMember readonly fileId?: Uuid;
  @jsonMember({ name: 'termsId' }) readonly regulationId?: Uuid;

  constructor(
    name: string,
    lead: string,
    description: string,
    identifier: string,
    status: OfferStatus,
    isDisposable: boolean,
    visibilityFor: OfferVisibilityFor,
    beginsAt: Date,
    expiresAt: Date,
    fileId?: Uuid,
    regulationsId?: Uuid,
  ) {
    this.name = name;
    this.lead = lead;
    this.description = description;
    this.identifier = identifier;
    this.status = status;
    this.isDisposable = isDisposable;
    this.visibilityFor = visibilityFor;
    this.beginsAt = beginsAt;
    this.expiresAt = expiresAt;
    this.fileId = fileId;
    this.regulationId = regulationsId;
  }
}
