import {
  ADMINS,
  AUTH,
  CUSTOMERS,
  EMPLOYEES,
  FILES,
  OFFERS,
  PROMOTION,
  REGULATIONS,
  STATISTICS,
} from '@environments/consts/endpoints';

export const environment = {
  production: false,
  api: {
    AUTH: { ...AUTH, HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin' },
    CUSTOMERS: {
      ...CUSTOMERS,
      HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin',
    },
    ADMINS: { ...ADMINS, HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin' },
    OFFERS: { ...OFFERS, HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin' },
    FILES: { ...FILES, HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin' },
    EMPLOYEES: {
      ...EMPLOYEES,
      HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin',
    },
    STATISTICS: {
      ...STATISTICS,
      HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin',
    },
    REGULATIONS: {
      ...REGULATIONS,
      HOST_URL: 'https://api.demo.promo.sanzaru.pl/admin',
    },
    PROMOTION: {
      ...PROMOTION,
      HOST_URL: 'https://api.demo.promo.sanzaru.pl/influencer',
    },
  },
};
