import { jsonMember, jsonObject } from 'typedjson';
import { dateSerializer } from '@core/json/serializers/date-serializer';

@jsonObject
export class OfferCollectionFilterRequest {
  @jsonMember name?: string;
  @jsonMember lead?: string;
  @jsonMember identifier?: string;
  @jsonMember status?: string;
  @jsonMember isDisposable?: boolean;
  @jsonMember({ name: 'visibility' }) visibilityFor?: string;
  @jsonMember({ serializer: dateSerializer() }) expiresAtFrom?: Date;
  @jsonMember({ serializer: dateSerializer() }) expiresAtTo?: Date;
  @jsonMember({ serializer: dateSerializer() }) createdAtFrom?: Date;
  @jsonMember({ serializer: dateSerializer() }) createdAtTo?: Date;
}
