export enum RouterPathFragment {
  AUTH = 'auth',
  SIGN_IN = 'sign-in',
  SIGN_OUT = 'sign-out',
  ACTIVATION = 'activation',
  RESET_PASSWORD = 'reset-password',
  RECOVER_PASSWORD = 'recover-password',
  CUSTOMERS = 'customers',
  CREATE = 'create',
  UPDATE = 'update',
  WALLET = 'wallet',
  SHARE_PREVIEW = 'share-preview',
  PREVIEW = 'preview',
  NOT_FOUND = 'not-found',
  OFFERS = 'offers',
  ADMINS = 'admins',
  EMPLOYEES = 'employees',
  STATISTICS = 'statistics',
  SUBSCRIPTIONS = 'subscriptions',
  PROMOTION = 'promotion',
  REGULATIONS = 'regulations',
  EMPTY = '',
  ROOT = '/',
  WILDCARD = '**',
}
